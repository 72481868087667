import { createSlice } from "@reduxjs/toolkit"
import { Filter, FlightInfo } from "../types"
import { getAirlineName } from "../utils"

export const flightsSlice = createSlice({
	name: "flights",
	initialState: {
		values: [] as FlightInfo[], // all flights returned from API
		filteredValues: [] as FlightInfo[], // filtered flights
		filteredUniqueValues: [] as FlightInfo[], // filtered flights without duplicates
		filters: {} as { [ key: string ]: Filter },
		airlines: {
			options: [] as {
				label: string,
				id: string
			}[],
			selected: [] as {
				label: string,
				id: string
			}[],
		}
	},
	reducers: {
		setFlights: (
			state: {
				values: FlightInfo[],
				airlines: {
					options: { label: string, id: string }[],
					selected: { label: string, id: string }[]
				}
			},
			action: { payload: FlightInfo[] }
		) => {
			state.values = action.payload

			// set airline list based on returned flight info
			const airlines: { label: string; id: string }[] = []



			action.payload.forEach(
				(flight: {
					airline?: string
					leg1?: { airline?: string }
					leg2?: { airline?: string }
				}) => {
					// check direct flights
					flight.airline && addAirlineToList(flight.airline, airlines)
					// check connecting flights
					flight.leg1 &&
						flight.leg1.airline &&
						addAirlineToList(flight.leg1.airline, airlines)
					flight.leg2 &&
						flight.leg2.airline &&
						addAirlineToList(flight.leg2.airline, airlines)
				}
			)

			state.airlines.options = airlines
			state.airlines.selected = airlines
		},
		addFlights: (state: { values: FlightInfo[] }, action: { payload: FlightInfo[] }) => {
			state.values = [ ...state.values, ...action.payload ]
		},
		updateFilteredFlights: (
			state: {
				values: FlightInfo[],
				filteredValues: FlightInfo[],
				filteredUniqueValues: FlightInfo[],
				filters: { [ key: string ]: Filter }
				airlines: {
					options: { label: string, id: string }[],
					selected: { label: string, id: string }[]
				}
			},
		) => {
			// filter values based on filters
			state.filteredValues = state.values.filter((flight) =>
				Object.values(state.filters).reduce(
					(acc, filter) => acc && filter(flight),
					true
				)
			)

			// update the airline list based on the filtered flights
			const airlines: { label: string; id: string }[] = []
			state.filteredValues.forEach((flight) => {
				flight.airline && addAirlineToList(flight.airline, airlines)
				flight.leg1?.airline && addAirlineToList(flight.leg1.airline, airlines)
				flight.leg2?.airline && addAirlineToList(flight.leg2.airline, airlines)
			})

			// save old airline list
			const oldAirlines = [ ...state.airlines.options ]
			state.airlines.options = airlines

			// select any airlines that were just added to the options list
			state.airlines.options.forEach(airline => {
				if (!oldAirlines.find(a => a.id === airline.id)) {
					state.airlines.selected.push(airline)
				}
			})

			// get airline Ids for selected airlines
			const selectedAirlineIds = state.airlines.selected.map((a) => a.id)

			// filter out flights where the airline isn't selected
			state.filteredValues = state.filteredValues.filter((flight) =>
			(
				(flight.airline && selectedAirlineIds.includes(flight.airline)) ||
				(
					flight.leg1?.airline && flight.leg2?.airline &&
					selectedAirlineIds.includes(flight.leg1.airline) &&
					selectedAirlineIds.includes(flight.leg2.airline)
				)
			))

			// set filtered unique values

			state.filteredUniqueValues = []
			state.filteredValues.forEach((flight) =>
				!state.filteredUniqueValues.find(
					(f) =>
						f.airline === flight.airline &&
						f.flightNumber === flight.flightNumber &&
						f.leg1?.airline === flight.leg1?.airline &&
						f.leg1?.flightNumber === flight.leg1?.flightNumber &&
						f.leg2?.airline === flight.leg2?.airline &&
						f.leg2?.flightNumber === flight.leg2?.flightNumber
				) &&
				state.filteredUniqueValues.push(flight)
			)

			// remove selected airlines that aren't in the options
			state.airlines.selected = state.airlines.selected.filter(airline =>
				!!state.airlines.options.find(option => option.id === airline.id)
			)
		},
		setAirlineOptions: (
			state: { airlines: { options: { label: string, id: string }[] } },
			action: { payload: { label: string, id: string }[] }
		) => {
			state.airlines.options = action.payload
		},
		setSelectedAirlines: (
			state: { airlines: { selected: { label: string, id: string }[] } },
			action: { payload: { label: string, id: string }[] }
		) => {
			state.airlines.selected = action.payload
		},
		selectAllAirlines: (
			state: {
				airlines: {
					options: { label: string, id: string }[],
					selected: { label: string, id: string }[],
				}
			}) => {
			state.airlines.selected = state.airlines.options
		},
		setFilter: (state: { filters: { [ key: string ]: Filter } }, action: { payload: { key: string, val: Filter } }) => {
			state.filters[ action.payload.key ] = action.payload.val
		},
	},
})


export const {
	setFlights,
	addFlights,
	setAirlineOptions,
	setSelectedAirlines,
	selectAllAirlines,
	setFilter,
	updateFilteredFlights
} = flightsSlice.actions

export default flightsSlice.reducer

/**
		 * function adds airline to airline list without duplicates
		 *
		 * @param airline {string} airline code
		 */
const addAirlineToList = (airline: string, airlines: { label: string, id: string }[]) => {
	if (!airlines.find((a) => a.id === airline)) {
		airlines.push({
			label:
				getAirlineName(airline) || airline,
			id: airline,
		})
	}
}