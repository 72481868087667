import React, { useEffect } from "react"
import styled from "styled-components"
import {
	formatTime,
	getAircraftType,
	getDateTimeVal,
	getTimezoneOffset,
} from "../../utils"
import style from "./style"
import { FlightDescriptionProps as FlightDescriptionProps } from "./types"

const FlightDescription = ({
	className,
	flightInfo,
}: FlightDescriptionProps) => {
	const [arrivalDate, setArrivalDate] = React.useState(new Date())
	const [departureDate, setDepartureDate] = React.useState(new Date())

	// properly parse both departure and arrival date
	useEffect(() => {
		const arrivalParts = flightInfo.arrivalDate?.split("-")
		arrivalParts &&
			arrivalParts.length >= 3 &&
			arrivalDate.setFullYear(
				parseInt(arrivalParts[0]),
				parseInt(arrivalParts[1]) - 1,
				parseInt(arrivalParts[2])
			)
		setArrivalDate(new Date(arrivalDate))

		const departureParts = flightInfo.departureDate?.split("-")
		departureParts &&
			departureParts.length >= 3 &&
			departureDate.setFullYear(
				parseInt(departureParts[0]),
				parseInt(departureParts[1]) - 1,
				parseInt(departureParts[2])
			)
		setDepartureDate(new Date(departureDate))
	}, [flightInfo])

	return flightInfo.leg1 ? (
		<div className={className}>
			<div className="connecting-col">
				<FlightDescription
					className={`leg1-${className}`}
					flightInfo={flightInfo.leg1}
				/>
				<div className="info-row subtle">
					<span>
						{`Layover:  ${formatTime(
							getDateTimeVal(
								flightInfo.leg2?.departureDate,
								flightInfo.leg2?.departureTime,
								flightInfo.leg2?.origin
							) -
								getDateTimeVal(
									flightInfo.leg1?.arrivalDate,
									flightInfo.leg1?.arrivalTime,
									flightInfo.leg1?.destination
								)
						)}`}
					</span>
				</div>
				<FlightDescription
					className={`leg2-${className}`}
					flightInfo={flightInfo.leg2!}
				/>
			</div>
		</div>
	) : (
		<div className={className}>
			<div className="connector">
				<div className="dot" />
				<div className="line" />
				<div className="dot" />
			</div>
			<div className="time-col">
				<div className="info-row">
					<span className="departure">
						{flightInfo.departureTime}
						<span className="timezone">
							{" UTC "}
							{getTimezoneOffset(flightInfo.origin)}
						</span>
					</span>
					<span className="airport-code">{flightInfo.origin}</span>
				</div>
				<div className="info-row subtle">
					<span>
						Travel time:
						{"     "}
						{formatTime(
							getDateTimeVal(
								flightInfo.arrivalDate,
								flightInfo.arrivalTime,
								flightInfo.destination
							) -
								getDateTimeVal(
									flightInfo.departureDate,
									flightInfo.departureTime,
									flightInfo.origin
								)
						)}
					</span>
				</div>
				<div className="info-row subtle">
					<span>{`${flightInfo.airline} ${flightInfo.flightNumber}`}</span>
					<span className="secondary-info">
						{getAircraftType(flightInfo.aircraftType || "")}
						{"  -  "}
						{flightInfo.aircraftType}
					</span>
				</div>
				<div className="info-row">
					<span className="arrival">
						{flightInfo.arrivalTime}
						<sup>
							{departureDate.getUTCDate() !== arrivalDate.getUTCDate() &&
								`+${(
									(arrivalDate.getTime() - departureDate.getTime()) /
									(1000 * 60 * 60 * 24)
								).toString()}`}
						</sup>
						<span className="timezone">
							{" UTC "}
							{getTimezoneOffset(flightInfo.destination)}
						</span>
					</span>
					<span className="airport-code">{flightInfo.destination}</span>
				</div>
			</div>
		</div>
	)
}

export default styled(FlightDescription)`
	${style}
`
