import { toRem, hexToRgbA } from "../../utils"
import { media } from "styled-bootstrap-grid";
import { css } from "styled-components";
import theme from "../../theme";

export default css`
  padding: 5px;

  .panel-value {
    font-size: ${theme.brand.font.size.sml};
    letter-spacing: 0px;
    font-weight: 700;
    display: block;
    margin-top: ${toRem(15)};
    line-height: 1;
  }

  .panel-value sub {
    font-size: ${theme.brand.font.size.xxs};
    vertical-align: baseline;
    font-weight: normal;
    padding-left: ${toRem(5)};
  }

  .title-panel {
    margin: ${toRem(-26)};
    margin-bottom: ${toRem(30)};
  }

  .title-panel ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    color: ${theme.brand.text.tertiary};
    border-bottom: solid 1px ${theme.border.lightBlack};
  }

  .title-panel ul li {
    padding: ${toRem(32)};
    border-right: solid 1px ${theme.border.lightBlack};
  }

  .title-panel .to-from {
    border-right: none !important;
    font-size: ${theme.brand.font.size.xl};
    font-weight: bold;
    padding-top: ${toRem(45)};
  }

  .results-panel ul {
    padding: 0;
    margin: 0 ${toRem(-13)};
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .results-panel ul li {
    padding: 0 ${toRem(13)};
    margin-bottom: ${toRem(25)};
  }

  ${media.max.tablet`
    .results-panel ul li {
      width: 100%;
      margin-bottom: ${toRem(0)};
    }
    .results-panel ul li input[type="checkbox"]::before {
      top: ${toRem(4)};
      left: ${toRem(4)};
    }
  `}

  .expandable {
    font-size: 20px;
    cursor: pointer;
    border-radius: ${toRem(12)};
    border: solid 1px ${theme.border.lightBlack};
    margin: auto;
    max-width: ${toRem(300)};
    padding: ${toRem(10)};
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;
