import styled from "styled-components"

import style from "./style"
import { CheckProps } from "./types"

const Checkbox = ({
	className,
	name,
	id,
	label,
	size,
	variation,
	...otherProps
}: CheckProps) => {
	return (
		<div className={className}>
			<input type="checkbox" name={name} id={id} {...otherProps} />
			{label && <label htmlFor={id}>{label}</label>}
		</div>
	)
}

export default styled(Checkbox)`
	${style}
`
