import { toRem, hexToRgbA } from "../../utils"
import { css } from "styled-components";
import theme from "../../theme";

export default css`
	display: flex;
	flex-direction: row;
	justify-content: center;

	.time-col {
		display: flex;
		flex-direction: column;
		margin: 15px 10px;
	}

	.connector {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin-right: ${toRem(10)};
	}

	.dot {
		height: 15%;
		border: 5px solid rgba(37, 37, 50, 0.3);
		border-radius: 100%;
		width: 5px;
		height: 5px;
	}

	.line {
		height: 50%;
		width: 0;
		border-left: 5px dotted rgba(37, 37, 50, 0.3);
		margin: 0 auto;
	}

	.info-row {
		display: flex;
		flex-direction: row;
		text-decoration: bold;
		font-size: ${toRem(20)};
	}

	.info-row>span {
		margin-top: 7.5px;
		margin-bottom: 7.5px;
	}

	.subtle {
		text-decoration: italic;
		font-size: 15px;
		color: ${hexToRgbA(theme.brand.color.black, 0.5)};
	}

	.departure, .arrival {
		margin-right: 20px;
	}

	.connecting-col {
		display: flex;
		flex-direction: column;
	}

	.secondary-info {
		margin-left: ${toRem(10)};
	}
`	