import React from 'react'
import style from './style'
import styled from 'styled-components'
import { ScheduleInfoProps } from './types'
import {
	getAircraftType,
	getAirlineName,
	getSmallerAircraft,
} from '../../utils'
import classNames from 'classnames'
import { ChevronL, PlaneGrey } from '../../icons'
import { DayOfWeek } from '../../types'

const LOGO_URL = 'https://www.gstatic.com/flights/airline_logos/70px/'

const daysOfWeek = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
] as DayOfWeek[]

const ScheduleConnectionInfo = ({
	className,
	flightInfo,
}: ScheduleInfoProps) => {
	const [expanded, setExpanded] = React.useState(false)

	return !expanded ? (
		<tr className={className} onClick={() => setExpanded(!expanded)}>
			<td>
				<div className="airline info row">
					<img
						className="airline-logo"
						src={`${LOGO_URL}${flightInfo.leg1?.airline}.png`}
						alt="airline logo"
					/>
					{flightInfo.leg1?.airline !== flightInfo.leg2?.airline && (
						<img
							className="airline-logo"
							src={`${LOGO_URL}${flightInfo.leg2?.airline}.png`}
							alt="airline logo"
						/>
					)}
					{(flightInfo.leg1?.serviceType === 'V' ||
						flightInfo.leg2?.serviceType === 'V') && (
						<img className="truck-logo" src="/truck.png" alt="truck logo" />
					)}
				</div>
			</td>
			<td>
				<div className="route info col">
					<span>{`${flightInfo.origin} - ${flightInfo.leg1?.destination} - ${flightInfo.destination}`}</span>
					{getAirlineName(flightInfo.leg1?.airline) || flightInfo.leg1?.airline}
					{flightInfo.leg1?.airline !== flightInfo.leg2?.airline &&
						` - ${
							getAirlineName(flightInfo.leg2?.airline) ||
							flightInfo.leg2?.airline
						}`}
				</div>
			</td>
			<td>
				<div className="aircraft info col">
					<span>
						{getSmallerAircraft(
							getAircraftType(flightInfo.leg1?.aircraftType) || '',
							getAircraftType(flightInfo.leg2?.aircraftType) || ''
						)}
					</span>
				</div>
			</td>
			<td>
				<div className="flight-number info row">
					<span>{`${flightInfo.leg1?.airline} ${flightInfo.leg1?.flightNumber} - ${flightInfo.leg2?.airline} ${flightInfo.leg2?.flightNumber}`}</span>
				</div>
			</td>
			{daysOfWeek.map((day, index) => (
				<td key={index}>
					{flightInfo.daysOfOperation?.includes(day as DayOfWeek) && (
						<PlaneGrey className="plane-icon" />
					)}
				</td>
			))}
			<td>
				<div className={`chevron row ${expanded && 'rotated'}`}>
					<ChevronL />
				</div>
			</td>
		</tr>
	) : (
		<tr className={className} onClick={() => setExpanded(!expanded)}>
			<td>
				<div className="airline info row">
					<img
						className="airline-logo"
						src={`${LOGO_URL}${flightInfo.leg1?.airline}.png`}
						alt="airline logo"
					/>
					{flightInfo.leg1?.serviceType === 'V' && (
						<img className="truck-logo" src="/truck.png" alt="truck logo" />
					)}
				</div>
				<div className="airline info row leg2">
					<img
						className="airline-logo"
						src={`${LOGO_URL}${flightInfo.leg2?.airline}.png`}
						alt="airline logo"
					/>
					{flightInfo.leg2?.serviceType === 'V' && (
						<img className="truck-logo" src="/truck.png" alt="truck logo" />
					)}
				</div>
			</td>
			<td>
				<div className="route info col">
					<span>{`${flightInfo.leg1?.origin} - ${flightInfo.leg1?.destination}`}</span>
					<span>{getAirlineName(flightInfo.leg1?.airline)}</span>
				</div>
				<div className="route info col leg2">
					<span>{`${flightInfo.leg2?.origin} - ${flightInfo.leg2?.destination}`}</span>
					<span>{getAirlineName(flightInfo.leg2?.airline)}</span>
				</div>
			</td>
			<td>
				<div className="aircraft info col">
					<span>{getAircraftType(flightInfo.leg1?.aircraftType) || ' '}</span>
					<span>{flightInfo.leg1?.aircraftType}</span>
				</div>
				<div className="aircraft info col leg2">
					<span>{getAircraftType(flightInfo.leg2?.aircraftType) || ' '}</span>
					<span>{flightInfo.leg2?.aircraftType}</span>
				</div>
			</td>
			<td>
				<div className="flight-number info row">
					<span>{`${flightInfo.leg1?.airline} ${flightInfo.leg1?.flightNumber}`}</span>
				</div>
				<div className="flight-number info row leg2">
					<span>{`${flightInfo.leg2?.airline} ${flightInfo.leg2?.flightNumber}`}</span>
				</div>
			</td>
			{daysOfWeek.map((day, index) => (
				<td key={index}>
					<div className="info">
						{flightInfo.leg1?.daysOfOperation?.includes(day as DayOfWeek) && (
							<PlaneGrey className="plane-icon" />
						)}
					</div>
					<div className="info leg2">
						{flightInfo.leg2?.daysOfOperation?.includes(day as DayOfWeek) && (
							<PlaneGrey className="plane-icon" />
						)}
					</div>
				</td>
			))}
			<td>
				<div className={`chevron row ${expanded && 'rotated'}`}>
					<ChevronL />
				</div>
			</td>
		</tr>
	)
}

export default styled(ScheduleConnectionInfo)`
	${style}
`
