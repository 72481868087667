import React from 'react'
import style from './style'
import styled from 'styled-components'
import { ScheduleDirectInfoProps } from './types'
import {
	getAircraftType,
	getAirlineName,
	getSmallerAircraft,
} from '../../utils'
import { PlaneGrey } from '../../icons'
import { DayOfWeek } from '../../types'

const LOGO_URL = 'https://www.gstatic.com/flights/airline_logos/70px/'

const daysOfWeek = [
	'sunday',
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
] as DayOfWeek[]

const ScheduleDirectInfo = ({
	className,
	flightInfo,
}: ScheduleDirectInfoProps) => {
	return (
		<tr className={className}>
			<td>
				<div className="airline info row">
					<img
						className="airline-logo"
						src={`${LOGO_URL}${flightInfo.airline}.png`}
						alt="airline logo"
					/>
					{flightInfo.serviceType === 'V' && (
						<img className="truck-logo" src="/truck.png" alt="truck logo" />
					)}
				</div>
			</td>
			<td>
				<div className="route info col">
					<span>{`${flightInfo.origin} - ${flightInfo.destination}`}</span>
					<span>{getAirlineName(flightInfo.airline)}</span>
				</div>
			</td>
			<td>
				<div className="aircraft info col">
					<span>{getAircraftType(flightInfo.aircraftType) || ''}</span>
					<span>{flightInfo.aircraftType}</span>
				</div>
			</td>
			<td>
				<div className="flight-number info row">
					<span>{`${flightInfo.airline} ${flightInfo.flightNumber}`}</span>
				</div>
			</td>
			{daysOfWeek.map((day, index) => (
				<td key={index}>
					{flightInfo.daysOfOperation?.includes(day as DayOfWeek) && (
						<PlaneGrey className="plane-icon" />
					)}
				</td>
			))}
			<td />
		</tr>
	)
}

export default styled(ScheduleDirectInfo)`
	${style}
`
