import { css } from "styled-components";
import { toRem, hexToRgbA } from "../../utils"
import { media } from "styled-bootstrap-grid";

import theme from "../../theme";
import { CardProps } from "./types";

export default css`
  padding: ${toRem(25)};
  border: solid 1px ${hexToRgbA(theme.border.landingCard, 0.3)};
  background-color: ${theme.brand.color.white};
  border-radius: ${toRem(12)};
`;
