import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`
	margin: 10px;

	table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 10px;
	}

	.table-title>th{
		padding: 10px;
		font-size: ${toRem(theme.brand.font.size.md)};
		margin-top: 20px;
		font-weight: normal;
		border-bottom: 1px solid rgba(37, 37, 50, 0.3);
	}

	.table-title {
		position: sticky;
		top: 0px;
		background-color: white;
	}

	tr {
		border: none;
	}

	td {
		border: 1px solid rgba(37, 37, 50, 0.3);
		border-style: solid none;
	}

	td:first-child {
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
		border-left-style: solid;
	}

	td:last-child {
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
		border-right-style: solid;
	}

	.selected {
		font-weight: bold;
	}

`