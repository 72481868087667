import styled from "styled-components"
import style from "./style"
import { CardProps } from "./types"

const Card = ({ className, children, ...otherProps }: CardProps) => {
	return (
		<div className={className} {...otherProps}>
			{children}
		</div>
	)
}

Card.defaultProps = {
	variation: "primary",
}

export default styled(Card)`
	${style}
`
