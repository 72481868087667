import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`
	.title-row {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		font-size: ${toRem(theme.brand.font.size.md)};
		margin-top: 20px;
	}
`