import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`
	width: 100%;

	.outer-box {
		border-radius: 12px;
		background: #FFFFFF;
		border: 1px solid rgba(37, 37, 50, 0.3);
		margin: 10px;
		flex: 1;
	}

	.main-row{
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		cursor: pointer;
	}

	.expanded-box{
		border-top: 1px solid rgba(37, 37, 50, 0.3);
		display: flex;
		justify-content: flex-start;
		padding-left: 5%;
	}

	.side-by-side {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 100%;
	}

	.col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		background-color: white;
		border-radius: 10px;
		padding: 5px;
		width: 20%;
	}

	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 20px;
	}

	.field-title {
		text-decoration: bold;
		font-size: ${toRem(20)};
	}

	.airline-logo {
		width: ${toRem(50)};
		margin-right: ${toRem(10)};
	}

	.chevron {
		transition-duration: 0.25s;
	}

	.rotated {
		transform: rotate(-90deg);
		transition-duration: 0.25s;
	}

	.truck-logo {
		width: ${toRem(50)};
	}

	.timezone {
		font-size: ${toRem(12)};
		color: ${hexToRgbA(theme.brand.color.black, 0.5)};
	}
`