import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin: 10px;
	border-radius: 6px;
	border: 1px solid rgba(37, 37, 50, 0.3);

	.selector {
		border: none;
		background: none;
		color: inherit;
		outline: inherit;
		font-size: ${toRem(20)};
		width: 100%;
		cursor: pointer;
		padding: 5px;
	}

	.selected {
		background: ${theme.brand.color.black};
		color: ${theme.brand.color.white};
	}

`