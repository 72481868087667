import React, { useEffect } from 'react'
import './App.css'
import Search from './components/Search'
import Button from './components/Button'
import Flights from './cards/Flights'
import { useDispatch, useSelector } from 'react-redux'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { TailSpin } from 'react-loader-spinner'
import {
	setFlights,
	addFlights,
	updateFilteredFlights,
} from './stores/flightsSlice'
import { getFlightInfo } from './api'
import { RootState } from './stores/store'
import {
	setArriveByDate,
	setDropOffDate,
	setInterAirport,
	setOld,
	setSearchConnecting,
	setSearchDirect,
} from './stores/searchParamsSlice'
import Schedules from './cards/Schedules'
import PageSelector from './components/PageSelector'
import { validate } from './utils/validateSearchParams'

const App = () => {
	// stores next tokens
	const [flightInfoNextToken, setFlightInfoNextToken] = React.useState('')
	const [connectionsNextToken, setConnectionsNextToken] = React.useState('')
	const [selectedPage, setSelectedPage] = React.useState('Flights')
	const [loading, setLoading] = React.useState(false)

	const PAGE_OPTIONS = [
		{ label: 'Flights', value: 'Flights' },
		{ label: 'Schedules', value: 'Schedules' },
	]

	const searchParams = useSelector(
		(state: RootState) => state.searchParams.current
	)
	const oldSearchParams = useSelector(
		(state: RootState) => state.searchParams.old
	)

	const dispatch = useDispatch()

	// when the page first loads sync the persisted data with the non persisted data
	useEffect(() => {
		dispatch(setSearchDirect(true))
		dispatch(setSearchConnecting(true))
		dispatch(setInterAirport(true))
	}, [])

	return (
		<div className="App-body">
			<Search
				className="searchBox"
				getFlightInfo={() => {
					if (validate(searchParams)) {
						setLoading(true)
						return getFlightInfo(searchParams)
							.then(
								({ flightInfo, flightInfoNextToken, connectionsNextToken }) => {
									dispatch(setFlights(flightInfo))
									setFlightInfoNextToken(flightInfoNextToken)
									setConnectionsNextToken(connectionsNextToken)
									dispatch(updateFilteredFlights())
									dispatch(setOld())
									setLoading(false)
								}
							)
							.catch((e) => {
								console.error("Couldn't load flights", e)
								alert(
									`Couldn't load flights: ${
										JSON.stringify(e.flightInfo?.data?.data?.errors) ||
										JSON.stringify(e.connections?.data?.data?.errors) ||
										e.message
									}`
								)
								setLoading(false)
							})
					} else {
						return Promise.reject('Invalid search params')
					}
				}}
			/>
			<PageSelector
				className="pageSelector"
				options={PAGE_OPTIONS}
				selected={selectedPage}
				setSelected={setSelectedPage}
			/>
			{selectedPage === 'Flights' ? (
				<Flights className="Flights" />
			) : (
				<Schedules className="Schedules" />
			)}
			{loading && (
				<div className="spinner-row">
					<TailSpin
						height="100"
						width="100"
						color="orange"
						ariaLabel="loading"
					/>
				</div>
			)}
			{flightInfoNextToken !== '' && (
				<Button
					className="load-more-button"
					onClick={() => {
						setLoading(true)
						return getFlightInfo({
							...oldSearchParams,
							flightInfoNext: flightInfoNextToken,
							connectingNext: connectionsNextToken,
						})
							.then(
								({ flightInfo, flightInfoNextToken, connectionsNextToken }) => {
									dispatch(addFlights(flightInfo))
									setFlightInfoNextToken(flightInfoNextToken)
									setConnectionsNextToken(connectionsNextToken)
									dispatch(updateFilteredFlights())
									setLoading(false)
								}
							)
							.catch((e) => {
								console.error("Couldn't load flights", e)
								alert(
									`Couldn't load flights: ${
										JSON.stringify(e.flightInfo?.data?.data?.errors) ||
										JSON.stringify(e.connections?.data?.data?.errors) ||
										e.message
									}`
								)
								setLoading(false)
							})
					}}
				>
					Load more
				</Button>
			)}
		</div>
	)
}

export default App
