import { useEffect } from "react";
import AIRPORTS from "../data/airports.json"
import AIRCRAFT from "../data/aircraftTypeClassification.json"
import AIRLINES from '../data/airlineCodes.json'

export const hexToRgbA = (hex: string, alpha?: number) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [ c[ 0 ], c[ 0 ], c[ 1 ], c[ 1 ], c[ 2 ], c[ 2 ] ];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [ (c >> 16) & 255, (c >> 8) & 255, c & 255 ].join(",") +
      "," +
      (alpha || 1) +
      ")"
    );
  }
  throw new Error("Bad Hex");
};

export const toRem = (value: number | string) => {
  if (String(value).includes("px")) {
    return Number(String(value).split("px")[ 0 ]) / 16 + "rem";
  }
  return Number(value) / 16 + "rem";
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideEvent = (ref: any, callback: Function) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ ref ]);
};

export const isMobile = () => {
  let isMobile = false;
  if (typeof window !== "undefined") {
    isMobile = window.innerWidth < 767;
  }

  return isMobile;
};

// convert milliseconds to a string with hours minutes and seconds
export const formatTime = (time: number): string => {
  const hours = Math.floor(time / 3599999)
  const minutes = Math.floor((time % 3599999) / 60000)
  const seconds = Math.floor((time % 59999) / 1000)
  return `${hours}h ${minutes}m`
}

// gets timezone offset for given timezone
export const getTimezoneOffset = (
  airportString: string | undefined,
  padding: number = 0
): string => {
  const timeZone = AIRPORTS.find(
    (airport) => airport.code === airportString
  )?.time_zone_id

  const date = new Date()
  const tz = date
    .toLocaleString("en", { timeZone, timeStyle: "long" })
    .split(" ")
    .slice(-1)[ 0 ]
  const dateString = date.toString()
  const offset =
    Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`)
  const finalOffset = offset / 1000 / 60 / 60
  return `${finalOffset > 0 ? "+" : "-"}${Math.abs(finalOffset)
    .toString()
    .padStart(padding, "0")}`
}

// gets date value for given date and time and timezone
export const getDateTimeVal = (date?: string, time?: string, timezoneLoc?: string): number => {
  return new Date(`${date}T${time}.000${getTimezoneOffset(timezoneLoc, 2)}:00`).getTime()
}

// gets the Aircraft type for a given aircraft code
export const getAircraftType = (aircraftCode?: string): string | undefined => {
  if (!aircraftCode) return undefined

  const aircraft = AIRCRAFT.find((aircraft: any) => aircraft?.[ "IATA Code" ] == aircraftCode)
  return aircraft?.[ "Type" ]
}

// gets the smaller of the two aircraft types
export const getSmallerAircraft = (a1: string, a2: string): string => {
  if (a1 === "Narrow Body" || a2 === "Narrow Body") return "Narrow Body"
  if (a1 === "Wide Body" || a2 === "Wide Body") return "Wide Body"
  if (a1 !== "" && a2 !== "") return "Freighter"
  return ""
}

// simple function to check if the selected aircraft type is in the list of aircraft types
export const checkForAircraftType = (
  inputAircraft: string | undefined,
  selected: { label: string, id: string }[],
  aircraftOptions: { label: string, id: string }[],
): boolean => {
  const selectedAircraftIds = selected.map((item) => item.id)
  const aircraftType = getAircraftType(inputAircraft)
  // if all selected then allow everything
  return selected.length === aircraftOptions.length ||
    // if we don't know the type of the aircraft then allow it
    !aircraftType ||
    // otherwise check if the input aircraft type is in the list of selected aircraft types
    !!(
      inputAircraft &&
      selectedAircraftIds.includes(
        aircraftType || inputAircraft
      )
    )
}

// gets the airline name for an airline code
export const getAirlineName = (airlineCode?: string): string | undefined => {
  if (!airlineCode) return undefined

  const airline = AIRLINES.find((airline) => airline.IATA === airlineCode)
  return airline?.[ 'Airline Name' ]
}

// correctly formats the date
export const parseDate = (date?: string): Date => {
  const finalDate = new Date()
  const dateParts = date?.split("-")
  dateParts &&
    dateParts.length >= 3 &&
    finalDate.setFullYear(
      parseInt(dateParts[ 0 ]),
      parseInt(dateParts[ 1 ]) - 1,
      parseInt(dateParts[ 2 ])
    )

  return new Date(finalDate)
}

// gets dates for rest of week for a given date
export const getWeekDates = (date: Date): Date[] => {
  const dates = []
  for (let i = 0; i < 7; i++) {
    dates.push(new Date(date.getTime() + i * 24 * 60 * 60 * 1000))
  }
  return dates
}