import { toRem, hexToRgbA } from "../../utils"
import { css } from "styled-components";

import theme from "../../theme";

export default css`
  border-radius: ${toRem(12)};

  label {
    font-weight: 400;
    font-size: ${toRem(19)};
    line-height: 1.45;
    display: inline-block;
    margin-bottom: ${toRem(10)};
    width: calc(100% - ${toRem(50)});
    vertical-align: top;
    cursor: point;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    cursor: default;
  }

  label span {
    margin-right: ${toRem(0)};
  }

  input {
    display: inline-block;
    font-size: ${toRem(19)};
    border: 0;
    padding: 0;
    outline: none;
    vertical-align: top;
    margin: ${toRem(5)} ${toRem(20)} 0 0;
    cursor: pointer;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: ${theme.heroSection.color};
    font: inherit;
    color: currentColor;
    width: ${toRem(22)};
    height: ${toRem(22)};
    border: 1px solid ${theme.border.checkboxTeal};
    border-radius: ${toRem(4)};
    transform: translateY(-0.075em);
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: ${toRem(13)};
    height: ${toRem(13)};
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: ${theme.brand.color.primary};
    display: block;
    position: relative;
    top: ${toRem(3)};
    left: ${toRem(3)};
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:focus {
    outline: none;
    outline-offset: unset;
  }
`;
