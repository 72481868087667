import React from 'react'
import styled from 'styled-components'
import style from './style'
import classNames from 'classnames'
import { PageSelectorProps } from './types'

const PageSelector = ({
	className,
	options,
	selected,
	setSelected,
}: PageSelectorProps) => {
	return (
		<div className={className}>
			{options.map((option, index) => (
				<button
					className={`selector ${option.value === selected && 'selected'}`}
					key={index}
					onClick={() => setSelected(option.value)}
				>
					{option.label}
				</button>
			))}
		</div>
	)
}

export default styled(PageSelector)`
	${style}
`
