import { hexToRgbA, toRem } from "../utils";

const brand = {
  font: {
    // family: "Suisse Intl, Arial, Helvetica, sans-serif",
    family: {
      primary: '"Inter", sans-serif',
      secondary: '"Poppins", sans-serif',
    },
    size: {
      xxxs: "15px",
      xxs: "16px",
      xs: "19px",
      sm: "21px",
      sml: "22px",
      md: "22px",
      lg: "27px",
      xl: "34px",
      xxl: "39px",
      jumbo: "49px",
    },
    lineHeight: 1.5,
  },
  color: {
    primary: "#EF6C22",
    secondary: "#f5ddcf",
    tertiary: "#2e2e32",
    white: "#FFF",
    teal: "#518990",
    blue: "#396EEE",
    blackSecondry: "#212121",
    black: "#000",
    lightBlue: "#EEF3F4",
    grey: "#252532",
  },
  text: {
    primary: "#000000",
    secondary: "#231F20",
    tertiary: "#2E2E32",
    grey: "#252532",
    white: "#FFF",
    teal: "#518990",
  },
};

const commonBorder = "#252532";

const border = {
  common: commonBorder,
  accordion: commonBorder,
  landingCard: commonBorder,
  black: "#000",
  white: "#FFF",
  lightBlack: hexToRgbA("#000000", 0.15),
  checkbox: commonBorder,
  textarea: commonBorder,
  checkboxTeal: "#518990",
};

const input = {
  bg: "#FFF",
  border: hexToRgbA("#252532", 0.3),
  borderHover: "#252532",
};

const heading = {
  h1: toRem(65),
  h2: "55px",
  h3: "45px",
  h4: "35px",
  h5: "25px",
  h6: "20px",
};

const headingMobile = {
  h2: "41px",
};

const button = {
  closeBtn: hexToRgbA("#252532", 0.5),
  primary: {
    color: brand.color.primary,
    size: "28px",
    text: "#FFF",
  },
  secondary: {
    color: brand.text.tertiary,
    text: "#FFF",
  },
  tertiary: {
    text: "#253C4F",
  },
  small: {
    size: "21px",
  },
  xsmall: {
    size: "16px",
  },
  grey: {
    color: "#253C4F",
    backgroundColor: hexToRgbA("#253C4F", 0.1),
  },
  light: {
    color: "#253C4F",
    backgroundColor: hexToRgbA("#FFF", 0.8),
  },
  transparent: {
    backgroundColor: "transparent",
  },
};

const footer = {
  color: "#F5DDCF",
};

const gridTheme = {
  breakpoints: {
    xxl: 1460,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 10, // default 15
  },
  col: {
    padding: 10,
  },
  container: {
    maxWidth: {
      xxl: 1436,
      xl: 1140,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    },
  },
};

const heroSection = {
  color: "#DFECF2",
};

const theme = {
  brand,
  heading,
  button,
  footer,
  gridTheme,
  border,
  heroSection,
  input,
  headingMobile,
};

export default theme;
