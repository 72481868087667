import { SearchParams } from "../types"

const AIRPORT_REGEX = /^[A-Z]{3}$/

export const validate = (searchParams: SearchParams): boolean => {
	// validate origin
	if (!AIRPORT_REGEX.test(searchParams.origin) && searchParams.origin !== "") {
		alert("Origin must be a valid 3 letter airport code")
		return false
	}

	// validate destination
	if (!AIRPORT_REGEX.test(searchParams.destination) && searchParams.destination !== "") {
		alert("Destination must be a valid 3 letter airport code")
		return false
	}

	// make sure there's at least an origin or destination
	if (searchParams.destination == "" && searchParams.origin == "") {
		alert("You must specify at least an origin or a destination")
		return false
	}

	// validate arrive by date
	if (new Date(searchParams.arriveByDate) < new Date(searchParams.dropOffDate)) {
		alert("Arrive by date must be after drop off date")
		return false
	}

	return true
}
