import React, { useEffect } from 'react'
import styled from 'styled-components'
import style from './style'
import { ShowFlightInfoProps as FlightInfoProps } from './types'

import { ChevronL } from '../../icons'
import FlightDescription from '../FlightDescription'
import {
	formatTime,
	getAircraftType,
	getAirlineName,
	getDateTimeVal,
	getSmallerAircraft,
	getTimezoneOffset,
	parseDate,
} from '../../utils'

const LOGO_URL = 'https://www.gstatic.com/flights/airline_logos/70px/'

const FlightInfo = ({ className, flightInfo }: FlightInfoProps) => {
	const [expanded, setExpanded] = React.useState(false)

	return (
		<div className={className}>
			<div className="outer-box">
				<div className="main-row" onClick={() => setExpanded(!expanded)}>
					{flightInfo.leg1 ? (
						<>
							<div className="airline col">
								<div className="logo row">
									<img
										className="airline-logo"
										src={`${LOGO_URL}${flightInfo.leg1?.airline}.png`}
										alt="airline logo"
									/>
									{flightInfo.leg1.airline !== flightInfo.leg2?.airline && (
										<img
											className="airline-logo"
											src={`${LOGO_URL}${flightInfo.leg2?.airline}.png`}
											alt="airline logo"
										/>
									)}
									{(flightInfo.leg1?.serviceType === 'V' ||
										flightInfo.leg2?.serviceType === 'V') && (
										<img
											className="truck-logo"
											src="/truck.png"
											alt="truck logo"
										/>
									)}
								</div>
							</div>

							<div className="route col">
								{!expanded && (
									<span>
										{flightInfo.origin}
										{'-'}
										{flightInfo.leg1?.destination}
										{'-'}
										{flightInfo.destination}
									</span>
								)}
								<span>
									{getAirlineName(flightInfo.leg1?.airline) ||
										flightInfo.leg1?.airline}
									{flightInfo.leg1?.airline !== flightInfo.leg2?.airline &&
										` - ${
											getAirlineName(flightInfo.leg2?.airline) ||
											flightInfo.leg2?.airline
										}`}
								</span>
							</div>

							{!expanded && (
								<div className="aircraft col">
									<span>
										{getSmallerAircraft(
											getAircraftType(flightInfo.leg1?.aircraftType) || '',
											getAircraftType(flightInfo.leg2?.aircraftType) || ''
										)}
									</span>
								</div>
							)}
						</>
					) : (
						<>
							<div className="airline col">
								<div className="logo row">
									<img
										className="airline-logo"
										src={`${LOGO_URL}${flightInfo.airline}.png`}
										alt="airline logo"
									/>
									{flightInfo.serviceType === 'V' && (
										<img
											className="truck-logo"
											src="/truck.png"
											alt="truck logo"
										/>
									)}
								</div>
							</div>

							<div className="route col">
								{!expanded && (
									<span>
										{flightInfo.origin}
										{'-'}
										{flightInfo.destination}
									</span>
								)}
								<span>
									{getAirlineName(flightInfo.airline) || flightInfo.airline}
								</span>
							</div>

							{!expanded && (
								<div className="aircraft col">
									<span>{getAircraftType(flightInfo.aircraftType || '')}</span>
									<span>{flightInfo.aircraftType}</span>
								</div>
							)}
						</>
					)}

					<div className="date col">
						<span>
							{parseDate(flightInfo.departureDate).toLocaleDateString('en-US', {
								month: 'short',
								day: 'numeric',
								year: 'numeric',
							})}
						</span>
					</div>

					{!expanded && (
						<div className="time col">
							<span>
								{flightInfo.departureTime}
								<span className="timezone">
									{' UTC '}
									{getTimezoneOffset(flightInfo.origin)}
								</span>
								{' - '}
								{flightInfo.arrivalTime}
								<sup>
									{parseDate(flightInfo.departureDate).getUTCDate() !==
										parseDate(flightInfo.arrivalDate).getUTCDate() &&
										`+${(
											(parseDate(flightInfo.arrivalDate).getTime() -
												parseDate(flightInfo.departureDate).getTime()) /
											(1000 * 60 * 60 * 24)
										).toString()}`}
								</sup>
								<span className="timezone">
									{' UTC '}
									{getTimezoneOffset(flightInfo.destination)}
								</span>
							</span>
						</div>
					)}
					<div className="total col">
						{!expanded && (
							<span>
								{/* Probably a bug here with timezones that are off by 1/2 hour increments  */}
								{formatTime(
									getDateTimeVal(
										flightInfo.arrivalDate,
										flightInfo.arrivalTime,
										flightInfo.destination
									) -
										getDateTimeVal(
											flightInfo.departureDate,
											flightInfo.departureTime,
											flightInfo.origin
										)
								)}
							</span>
						)}
						<span>{flightInfo.leg1 ? '1 stop' : 'Nonstop'}</span>
					</div>
					<div className={`chevron row ${expanded && 'rotated'}`}>
						<ChevronL />
					</div>
				</div>

				{expanded && (
					<div className="expanded-box">
						<FlightDescription
							className="flight-description"
							flightInfo={flightInfo}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default styled(FlightInfo)`
	${style}
`
