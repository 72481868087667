import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`
	.searchOuterBox {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		background-color: white;
		border-radius: 10px;
		margin: 20px;
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.input {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 10px;
		border-radius: 10px;
		border: 1px solid ${theme.input.border};
		font-size: 18px;
		box-sizing: border-box;
		margin-top: 6px;
		margin-bottom: 6px;
	}

	.input-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 10px;
		font-size: 18px;
		box-sizing: border-box;
		margin-top: 6px;
		margin-bottom: 6px;
	}

	.selector {
		width: 100%;
		margin: 6px;
	}
`
