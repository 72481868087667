import Multiselect from "multiselect-react-dropdown"
import { useEffect, useState } from "react"
import styled from "styled-components"

import style from "./style"

import { SelectProps } from "./types"

const Select = ({
	className,
	label,
	name,
	id,
	size,
	...otherProps
}: SelectProps) => {
	const [chip, setChip] = useState(0)

	useEffect(() => {
		if (!otherProps.singleSelect && !otherProps.isChips) {
			const targetNode = document.getElementById(id)

			if (targetNode) {
				const config = { attributes: true, childList: true, subtree: true }

				const callback = function () {
					setChip(targetNode.querySelectorAll(".chip").length)
				}

				// Create an observer instance linked to the callback function
				const observer = new MutationObserver(callback)

				// Start observing the target node for configured mutations
				observer.observe(targetNode, config)

				return () => {
					observer.disconnect()
				}
			}
		}
	}, [otherProps])

	return (
		<div className={className}>
			{label && <label htmlFor={id}>{label}</label>}
			{!!chip && <div className="selected-chip">{chip} Item selected...</div>}
			<Multiselect id={id} {...otherProps} />
		</div>
	)
}

export default styled(Select)`
	${style}
`
