import React from 'react'
import Card from '../../components/Card'
import Checkbox from '../../components/Checkbox'
import classNames from 'classnames'
import styled from 'styled-components'
import style from './style'
import { CardCheckboxProps, AirlineProps } from './types'
import { RootState } from '../../stores/store'
import { useDispatch, useSelector } from 'react-redux'
import {
	setFilter,
	setSelectedAirlines,
	updateFilteredFlights,
} from '../../stores/flightsSlice'

const LOGO_URL = 'https://www.gstatic.com/flights/airline_logos/70px/'

const CardCheckbox = ({
	icon,
	label,
	id,
	...otherProps
}: CardCheckboxProps) => {
	return (
		<Card size="large" fill="#EEF3F4">
			<Checkbox
				className="checkbox"
				size="large"
				variation="secondary"
				label={
					<>
						<span>
							<img
								className="airline-logo"
								src={`${LOGO_URL}${id}.png`}
								alt="airline logo"
							/>
						</span>
						<span>{label}</span>
					</>
				}
				{...otherProps}
			/>
		</Card>
	)
}

const Airlines = ({ className }: AirlineProps) => {
	const [expanded, setExpanded] = React.useState(false)

	// global store
	const dispatch = useDispatch()

	// load airlines from global store
	const options = useSelector(
		(state: RootState) => state.flights.airlines.options
	)

	// load selected airlines from global store
	const selected = useSelector(
		(state: RootState) => state.flights.airlines.selected
	)

	// runs on checkbox click and runs onChange with the new selected values
	const onItemChange = (item: any) => {
		let newSelected = []
		if (selected.map((airline) => airline.id).includes(item.id)) {
			newSelected = selected.filter((i) => i.id !== item.id)
		} else {
			newSelected = [...selected, item]
		}
		dispatch(setSelectedAirlines(newSelected))
		// we do not create a filter for airlines as these are derived by which flights are not filtered out
		// and so we avoid a circular dependency
		// this is instead handled in the reducer

		dispatch(updateFilteredFlights())
	}

	return (
		<Card className={classNames('search-results', className)}>
			{expanded ? (
				<div className="results-panel">
					<ul>
						{options.map((item) => (
							<li key={item.id}>
								<CardCheckbox
									defaultChecked
									icon={'item.image'}
									label={item.label}
									id={item.id}
									onChange={() => onItemChange(item)}
								/>
							</li>
						))}
					</ul>
					<div className="expandable" onClick={() => setExpanded(!expanded)}>
						Collapse
					</div>
				</div>
			) : (
				<div className="expandable" onClick={() => setExpanded(!expanded)}>
					Expand Airlines
				</div>
			)}
		</Card>
	)
}

export default styled(Airlines)`
	${style}
`
