import { FlightInfo, SearchParams } from "../types"

/**
 * 
 * @param searchParams {SearchParams} search parameters
 * @returns {Promise<FlightInfo[]>} flight info
 */
export const getFlightInfo = async (
	searchParams: SearchParams,
): Promise<{
	flightInfo: FlightInfo[],
	flightInfoNextToken: string,
	connectionsNextToken: string
}> => {
	const requestOptions = {
		method: "GET",
	}

	const backendEndpoint = (window as any).globalConfig.backendEndpoint

	// fetch request for direct flights
	const flightInfoResponse = searchParams.searchDirect
		? await fetch(
			`${backendEndpoint}/flight-info?${new URLSearchParams({
				origin: searchParams.origin,
				destination: searchParams.destination,
				dropOffDate: searchParams.dropOffDate
					.slice(0, 10)
					.toString(),
				arriveByDate: searchParams.arriveByDate
					.slice(0, 10)
					.toString(),
				next: searchParams.flightInfoNext || "",
			})}`,
			requestOptions
		)
		: undefined

	const flightInfoData = searchParams.searchDirect
		? await flightInfoResponse!.text()
		: "[]"

	// fetch request for connecting flights
	const connectionsResponse = searchParams.searchConnecting
		? await fetch(
			`${backendEndpoint}/connections?${new URLSearchParams({
				origin: searchParams.origin,
				destination: searchParams.destination,
				dropOffDate: searchParams.dropOffDate
					.slice(0, 10)
					.toString(),
				arriveByDate: searchParams.arriveByDate
					.slice(0, 10)
					.toString(),
				interAirport: searchParams.interAirport?.toString() || "false",
				next: searchParams.connectingNext || "",
			})}`,
			requestOptions
		)
		: undefined

	const connectionsData = searchParams.searchConnecting
		? await connectionsResponse!.text()
		: "[]"

	let parsedFlightInfoData: any
	let parsedConnectionsData: any

	if (flightInfoResponse?.status === 402 || connectionsResponse?.status === 402) {
		console.error("Request limit reached for this month!")
		throw {
			message: "Request limit reached for this month!",
			status: 402,
		}
	}

	try {
		parsedFlightInfoData = JSON.parse(flightInfoData)
	} catch {
		console.error("Error parsing flight info data", flightInfoData)
		throw {
			message: "Error parsing flight info data",
			flightInfo: flightInfoData
		}
	}
	try {
		parsedConnectionsData = JSON.parse(connectionsData)
	} catch {
		console.error("Error parsing connections data", connectionsData)
		throw {
			message: "Error parsing connections data",
			connections: connectionsData
		}
	}

	if (!Array.isArray(parsedFlightInfoData.data) && !Array.isArray(parsedConnectionsData.data)) {
		console.error("Error parsing both flight info data and connections data", parsedFlightInfoData, parsedConnectionsData)
		throw {
			message: "Both API's returned unexpected data!",
			flightInfo: parsedFlightInfoData,
			connections: parsedConnectionsData
		}
	} else if (!Array.isArray(parsedFlightInfoData.data)) {
		console.error("Flight info API returned unexpected data!", parsedFlightInfoData)
		throw {
			message: "Flight Info API returned unexpected data!",
			flightInfo: parsedFlightInfoData,
		}
	} else if (!Array.isArray(parsedConnectionsData.data)) {
		console.error("Connections API returned unexpected data!", parsedConnectionsData)
		throw {
			message: "Connections API returned unexpected data!",
			connections: parsedConnectionsData,
		}
	}
	try {

		// combine both direct results and connecting results and remove undefined entries
		const simpleFlightInfo = parsedFlightInfoData.data
			.concat(parsedConnectionsData.data)
			.filter((flight: FlightInfo) => !!flight)

		// parse flight info next token
		const flightInfoNextToken = JSON.parse(flightInfoData).next
		// setFlightInfoNextToken(flightInfoNextToken)

		// parse connections next token
		const connectionsNextToken = JSON.parse(connectionsData).next
		// setConnectionsNextToken(connectionsNextToken)

		return {
			flightInfo: simpleFlightInfo as FlightInfo[],
			flightInfoNextToken,
			connectionsNextToken,
		}
	} catch (e) {
		console.error("Error occurred while processing data")
		throw "Error occurred while processing data"
	}

}