import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`

	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.plane-icon {
		width: 40px;
		height: auto;
	}

	.info {
	}


	.airline>span {
		margin-left: 10px;
	}

	td {
		padding: 10px;
	}

	.truck-logo {
		width: ${toRem(50)};
	}
`