import React from 'react'
import styled from 'styled-components'
import style from './style'
import { SchedulesProps } from './types'
import ScheduleInfo from '../../components/ScheduleDirectInfo'
import { useSelector } from 'react-redux'
import { RootState } from '../../stores/store'
import ScheduleConnectionInfo from '../../components/ScheduleConnectionInfo'
import { getWeekDates } from '../../utils'
import { FlightInfo } from '../../types'

const Schedules = ({ className }: SchedulesProps) => {
	const filteredUniqueFlights = useSelector(
		(state: RootState) => state.flights.filteredUniqueValues
	)

	const searchparams = useSelector((state: RootState) => state.searchParams.old)
	const datesForWeek = getWeekDates(new Date(searchparams.dropOffDate))

	// day of week for searched date
	const day = new Date(searchparams.dropOffDate).getUTCDay() % 7

	const posMod = (n: number, m: number) => ((n % m) + m) % m

	return (
		<div className={className}>
			<table>
				<tbody>
					<tr className="table-title">
						<th className="table-title-text">Airline</th>
						<th className="table-title-text">Route</th>
						<th className="table-title-text">Aircraft</th>
						<th className="table-title-text">Flight Number</th>
						<th>
							<span className={`table-title-text ${day === 0 && 'selected'}`}>
								Sun (
								{datesForWeek[posMod(-day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th>
							<span className={`table-title-text ${day === 1 && 'selected'}`}>
								Mon (
								{datesForWeek[posMod(1 - day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th>
							<span className={`table-title-text ${day === 2 && 'selected'}`}>
								Tue (
								{datesForWeek[posMod(2 - day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th>
							<span className={`table-title-text ${day === 3 && 'selected'}`}>
								Wed (
								{datesForWeek[posMod(3 - day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th>
							<span className={`table-title-text ${day === 4 && 'selected'}`}>
								Thu (
								{datesForWeek[posMod(4 - day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th>
							<span className={`table-title-text ${day === 5 && 'selected'}`}>
								Fri (
								{datesForWeek[posMod(5 - day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th>
							<span className={`table-title-text ${day === 6 && 'selected'}`}>
								Sat (
								{datesForWeek[posMod(6 - day, 7)].toLocaleString('default', {
									month: 'short',
									day: 'numeric',
								})}
								)
							</span>
						</th>
						<th />
					</tr>
					{filteredUniqueFlights.map((flight: FlightInfo, index: number) =>
						flight.leg1 ? (
							<ScheduleConnectionInfo
								className={className}
								key={index}
								flightInfo={flight}
							/>
						) : (
							<ScheduleInfo
								className={className}
								key={index}
								flightInfo={flight}
							/>
						)
					)}
				</tbody>
			</table>
		</div>
	)
}

export default styled(Schedules)`
	${style}
`
