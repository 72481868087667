import { configureStore } from '@reduxjs/toolkit'
import flightsReducer from './flightsSlice'
import searchParamsReducer from './searchParamsSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

const persistConfig = {
	key: 'root',
	storage,
}

const persistedSearchParamsReducer = persistReducer(persistConfig, searchParamsReducer)

export const store = configureStore({
	reducer: {
		flights: flightsReducer,
		searchParams: persistedSearchParamsReducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>