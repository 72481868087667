import DatePicker from 'react-datepicker'
import '../../../node_modules/react-datepicker/dist/react-datepicker.css'
import Button from '../Button'
import style from './style'
import styled from 'styled-components'
import { SearchProps } from './types'
import Select from '../Select'
import FlightSelector from '../../cards/Airlines'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter, updateFilteredFlights } from '../../stores/flightsSlice'
import {
	setOrigin as setStateOrigin,
	setDestination as setStateDestination,
	setDropOffDate as setStateDropOffDate,
	setArriveByDate as setStateArriveByDate,
	setSearchDirect,
	setSearchConnecting,
	setInterAirport,
} from '../../stores/searchParamsSlice'
import { checkForAircraftType } from '../../utils'
import { RootState } from '../../stores/store'

const AIRCRAFT_OPTIONS = [
	{ label: 'Freighter', id: 'Freighter' },
	{ label: 'Wide Body', id: 'Wide Body' },
	{ label: 'Narrow Body', id: 'Narrow Body' },
]

const CONNECTION_OPTIONS = [
	{ label: 'Direct', id: 'Direct' },
	{ label: 'Online', id: 'Online' },
	{ label: 'Offline/Interline', id: 'Offline' },
	{ label: 'Inter Airport', id: 'InterAirport' },
]

const Search = ({ className, getFlightInfo }: SearchProps) => {
	// global store
	const dispatch = useDispatch()

	// get search params from global store
	const searchParams = useSelector(
		(state: RootState) => state.searchParams.current
	)

	// get old search params from global store
	const oldSearchParams = useSelector(
		(state: RootState) => state.searchParams.old
	)

	// get flights for search button
	const flights =
		useSelector((state: RootState) => state.flights.values).length > 0

	// set search params for input
	const setOrigin = (origin: string) => {
		dispatch(setStateOrigin(origin))
	}
	const setDestination = (destination: string) => {
		dispatch(setStateDestination(destination))
	}
	const setDropOffDate = (dropOffDate: Date) => {
		dispatch(setStateDropOffDate(dropOffDate))
	}
	const setArriveByDate = (arriveByDate: Date) => {
		dispatch(setStateArriveByDate(arriveByDate))
	}

	// handle change in aircraft options
	const onAircraftChange = (selected: { label: string; id: string }[]) => {
		dispatch(
			setFilter({
				key: 'aircraft',
				val: (flightInfo) =>
					(flightInfo.aircraftType &&
						checkForAircraftType(
							flightInfo.aircraftType,
							selected,
							AIRCRAFT_OPTIONS
						)) ||
					(!!flightInfo.leg1 &&
						checkForAircraftType(
							flightInfo.leg1?.aircraftType,
							selected,
							AIRCRAFT_OPTIONS
						) &&
						checkForAircraftType(
							flightInfo.leg2?.aircraftType,
							selected,
							AIRCRAFT_OPTIONS
						)),
			})
		)
		dispatch(updateFilteredFlights())
	}

	// handle change in connection options
	const onConnectionChange = (selected: { label: string; id: string }[]) => {
		// set new search params
		dispatch(
			setSearchDirect(!!selected.find((option) => option.id === 'Direct'))
		)
		dispatch(
			setSearchConnecting(
				!!selected.find((option) => option.id === 'Online') ||
					!!selected.find((option) => option.id === 'Offline')
			)
		)
		dispatch(
			setInterAirport(!!selected.find((option) => option.id === 'InterAirport'))
		)

		// filter direct flights
		dispatch(
			setFilter({
				key: 'searchDirect',
				val: (flightInfo) =>
					!!flightInfo.leg1 ||
					!!selected.find((option) => option.id === 'Direct'),
			})
		)
		// filter connecting flights
		dispatch(
			setFilter({
				key: 'connectionType',
				val: (flightInfo) =>
					!flightInfo.leg1 ||
					(flightInfo.connectionType === 'O' &&
						!!selected.find((option) => option.id === 'Online')) ||
					(flightInfo.connectionType === 'I' &&
						!!selected.find((option) => option.id === 'Offline')) ||
					(flightInfo.connectionType === 'N' &&
						!!selected.find((option) => option.id === 'Offline')),
			})
		)
		// filter inter airport flights
		dispatch(
			setFilter({
				key: 'interAirport',
				val: (flightInfo) =>
					!flightInfo.leg1 ||
					flightInfo.leg1?.destination === flightInfo.leg2?.origin ||
					!!selected.find((option) => option.id === 'InterAirport'),
			})
		)
		dispatch(updateFilteredFlights())
	}

	const safeDropOffDate = () => {
		if (
			!searchParams.dropOffDate ||
			new Date(searchParams.dropOffDate) == null
		) {
			dispatch(setStateDropOffDate(new Date()))
			return new Date()
		}
		return new Date(searchParams.dropOffDate)
	}

	const safeArriveByDate = () => {
		if (
			!searchParams.arriveByDate ||
			new Date(searchParams.arriveByDate) == null
		) {
			dispatch(setStateArriveByDate(new Date()))
			return new Date()
		}
		return new Date(searchParams.arriveByDate)
	}

	return (
		<div className={className}>
			<div className="searchOuterBox">
				<div className="input-box">
					<span>Route Origin</span>
					<input
						value={searchParams.origin}
						onChange={(event) => setOrigin(event.target.value)}
						className="origin input"
						placeholder="origin (optional if destination specified)"
					/>
				</div>
				<div className="input-box">
					<span>Route Destination</span>
					<input
						value={searchParams.destination}
						onChange={(event) => setDestination(event.target.value)}
						className="destination input"
						placeholder="destination (optional if origin specified)"
					/>
				</div>
				<div className="input-box">
					<span>Expected Drop Off</span>
					<DatePicker
						className="input"
						selected={safeDropOffDate()}
						onChange={(date: Date) => setDropOffDate(date)}
					/>
				</div>
				<div className="input-box">
					<span>Latest Acceptable Arrival</span>
					<DatePicker
						className="input"
						selected={safeArriveByDate()}
						onChange={(date: Date) => setArriveByDate(date)}
					/>
				</div>
			</div>
			<div className="searchOuterBox">
				<Select
					label="Connections"
					placeholder="Select Connection Options"
					name="connections"
					id="connection"
					options={CONNECTION_OPTIONS}
					displayValue="label"
					showCheckbox={true}
					className="connection-select selector"
					isChips
					selectedValues={CONNECTION_OPTIONS}
					onSelect={onConnectionChange}
					onRemove={onConnectionChange}
				/>
				<Select
					label="Aircraft"
					placeholder="Select Aircraft"
					name="aircraft"
					id="aircraft"
					options={AIRCRAFT_OPTIONS}
					displayValue="label"
					showCheckbox={true}
					className="aircraft-select selector"
					isChips
					selectedValues={AIRCRAFT_OPTIONS}
					onSelect={onAircraftChange}
					onRemove={onAircraftChange}
				/>
				<div className="">
					<Button
						onClick={() => {
							if (searchParams.interAirport && !searchParams.searchConnecting) {
								alert(
									'You must select either Online or Offline connections to search for inter-airport flights'
								)
								return
							}
							getFlightInfo().catch(console.error)
						}}
						// disable if new search is not needed
						disabled={
							flights &&
							((!searchParams.searchConnecting && !searchParams.searchDirect) ||
								!(
									oldSearchParams.origin !== searchParams.origin ||
									oldSearchParams.destination !== searchParams.destination ||
									oldSearchParams.dropOffDate !== searchParams.dropOffDate ||
									oldSearchParams.arriveByDate !== searchParams.arriveByDate ||
									(!oldSearchParams.searchDirect &&
										searchParams.searchDirect) ||
									(!oldSearchParams.searchConnecting &&
										searchParams.searchConnecting) ||
									(!oldSearchParams.interAirport && searchParams.interAirport)
								))
						}
					>
						Search
					</Button>
				</div>
			</div>
			<div className="searchOuterBox">
				<FlightSelector className="airline-select selector" />
			</div>
		</div>
	)
}

export default styled(Search)`
	${style}
`
