import { createSlice } from "@reduxjs/toolkit"
import { SearchParams } from "../types"


export const searchParamsSlice = createSlice({
	name: "searchParams",
	initialState: {
		current: {
			origin: '',
			destination: '',
			dropOffDate: new Date().toISOString(),
			arriveByDate: new Date().toISOString(),
			searchDirect: true,
			searchConnecting: true,
			interAirport: false,
		} as SearchParams,
		old: {
			origin: '',
			destination: '',
			dropOffDate: '',
			arriveByDate: '',
			searchDirect: true,
			searchConnecting: true,
			interAirport: false,
		} as SearchParams
	},
	reducers: {
		setOrigin: (state: { current: { origin: string } }, action: { payload: string }) => {
			state.current.origin = action.payload
		},
		setDestination: (state: { current: { destination: string } }, action: { payload: string }) => {
			state.current.destination = action.payload
		},
		setDropOffDate: (state: { current: { dropOffDate: string } }, action: { payload: Date }) => {
			state.current.dropOffDate = action.payload.toISOString()
		},
		setArriveByDate: (state: { current: { arriveByDate: string } }, action: { payload: Date }) => {
			state.current.arriveByDate = action.payload.toISOString()
		},
		setSearchDirect: (state: { current: { searchDirect: boolean } }, action: { payload: boolean }) => {
			state.current.searchDirect = action.payload
		},
		setSearchConnecting: (state: { current: { searchConnecting: boolean } }, action: { payload: boolean }) => {
			state.current.searchConnecting = action.payload
		},
		setInterAirport: (state: { current: { interAirport: boolean } }, action: { payload: boolean }) => {
			state.current.interAirport = action.payload
		},
		setOld: (state: {
			current: SearchParams,
			old: SearchParams
		}) => {
			state.old.origin = state.current.origin
			state.old.destination = state.current.destination
			state.old.dropOffDate = state.current.dropOffDate
			state.old.arriveByDate = state.current.arriveByDate
			state.old.searchDirect = state.current.searchDirect
			state.old.searchConnecting = state.current.searchConnecting
			state.old.interAirport = state.current.interAirport
		}
	}
})

export const {
	setOrigin,
	setDestination,
	setDropOffDate,
	setArriveByDate,
	setSearchDirect,
	setSearchConnecting,
	setInterAirport,
	setOld
} = searchParamsSlice.actions

export default searchParamsSlice.reducer