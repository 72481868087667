import { toRem, hexToRgbA } from "../../utils"
import { css } from "styled-components"
import { ButtonProps } from "./types"
import { media } from "styled-bootstrap-grid"
import theme from "../../theme"

export default css`
  display: inline-block;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  font-size: ${theme.button.primary.size};
  color: ${theme.button.primary.text};
  background-color: ${theme.button.primary.color};
  align-items: center;
  padding: ${toRem(8)} ${toRem(23)} ${toRem(8)} ${toRem(35)};
  border-radius: ${toRem(12)};

  :disabled {
    background-color: ${theme.brand.color.secondary};
    cursor: not-allowed;
  }

  span {
    vertical-align: middle;
    display: inline-flex;
  }

  span + span {
    margin-left: ${toRem(18)};
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: ${toRem(12)};
  }

`;
