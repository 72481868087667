import Link from "next/link"
import styled from "styled-components"

import style from "./style"
import { ButtonProps } from "./types"

const Button = ({
	children,
	href,
	className,
	variation,
	widthType,
	...otherProps
}: ButtonProps) => {
	if (href) {
		return (
			<Link href={href} {...otherProps}>
				<a className={className}>
					<>{children}</>
				</a>
			</Link>
		)
	}

	return (
		<button className={className} {...otherProps}>
			<>{children}</>
		</button>
	)
}

Button.defaultProps = {
	variation: "primary",
	widthType: "default",
}

export default styled(Button)`
	${style}
`
