import React from 'react'
import style from './style'
import styled from 'styled-components'
import FlightInfo from '../../components/FlightInfo'
import { FlightsProps } from './types'
import { useSelector } from 'react-redux'
import { FlightInfo as FlightInfoType } from '../../types'
import { RootState } from '../../stores/store'

const Flights = ({ className }: FlightsProps) => {
	const filteredFlights = useSelector(
		(state: RootState) => state.flights.filteredValues
	)

	return (
		<div className={className}>
			<div className="title-row">
				<span className="title-row-text-title">Airline</span>
				<span className="title-row-text-title">Route</span>
				<span className="title-row-text-title">Aircraft</span>
				<span className="title-row-text-title">Departure Date</span>
				<span className="title-row-text-title">Time</span>
				<span className="title-row-text-title">Duration</span>
			</div>
			{filteredFlights.map((flight, index) => (
				<FlightInfo
					className={`showFlightInfo-${index}`}
					key={index}
					flightInfo={flight}
				/>
			))}
		</div>
	)
}

export default styled(Flights)`
	${style}
`
