import { toRem, hexToRgbA } from "../../utils"
import { css } from "styled-components";
import { SelectProps } from "./types";
import theme from "../../theme"

export default css`
  padding: 15px;
  border: solid 1px ${theme.input.border};
  background-color: ${theme.input.bg};
  display: block;
  border-radius: 12px;
  margin-bottom: 25px;

  label {
    font-size: 13px;
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .search-wrapper {
    border: 0;
    padding: 0;
    cursor: pointer;
    display: flex;
    overflow: hidden;
  }

  input {
    display: block;
    font-size: 19px;
    border: 0;
    padding: 0;
    width: 100%;
    outline: none;
    margin: 0;
    cursor: pointer;
  }

  .optionListContainer {
    padding: 0 20px;
    background: #ffffff;
    border: 1px solid rgba(37, 37, 50, 0.3);
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    min-width: 335px;
    left: -17px;
    top: 48px;
  }

  .multiSelectContainer ul {
    border: none;
    padding: 0;
    border-radius: 0;
  }

  .multiSelectContainer li {
    padding: 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 18px;
    font-weight: 500;
    color: #000;
  }

  .multiSelectContainer li:last-child {
    border-bottom: none;
  }

  .multiSelectContainer li:hover,
  .highlightOption {
    background: #fff;
    color: #000;
  }

  .multiSelectContainer li input {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    margin-right: 15px;
    vertical-align: sub;
  }

  .search-wrapper.searchWrapper span.chip:not(.singleChip) {
    margin-right: 5px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1;
    padding-right: 18px;
    color: ${theme.brand.color.white};
    background: ${theme.brand.text.primary};
    border-radius: 6px;
  }

  .singleSelect {
    align-items: center;

    .icon_cancel {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      opacity: 0;
    }
  }

  .chip {
    &.singleChip {
      display: block;
      margin: 0;
      padding: 0;
      font-size: ${toRem(19)};
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 0;

      .closeIcon {
        display: none;
      }

      & + input {
        width: 20px;
      }
    }
  }

  .selected-chip {
    position: absolute;
    font-size: 19px;

    & + div .multiselect-container .chip {
      display: none;
    }

    & + div .multiselect-container input::-webkit-input-placeholder {
      opacity: 0;
    }

    & + div .multiselect-container input::-moz-placeholder {
      opacity: 0;
    }

    & + div .multiselect-container input:-ms-input-placeholder {
      opacity: 0;
    }

    & + div .multiselect-container input:-moz-placeholder {
      opacity: 0;
    }
  }

  .handling .multiSelectContainer .search-wrapper.searchWrapper {
    min-height: 1px;
    margin-bottom: -4px;
  }
  input[type="checkbox"] {
    display: inline-block;
    font-size: 19px;
    border: 0;
    padding: 0;
    outline: none;
    vertical-align: top;
    margin: 5px 20px 0 0;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font: inherit;
    color: currentColor;
    width: 22px;
    height: 22px;
    border: 3px solid #000;
    border-radius: 3px;
    -webkit-transform: translateY(-0.075em);
    -ms-transform: translateY(-0.075em);
    transform: translateY(-0.075em);
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 10px;
    height: 10px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: #fff;
    display: block;
    position: relative;
    top: 3px;
    left: 3px;
    z-index: 999;
  }

  input[type="checkbox"]:checked::after {
    content: "";
    display: block;
    background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border: none;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  input[type="checkbox"]:focus {
    outline: none;
    outline-offset: unset;
  }
`;
