import { css } from "styled-components"
import { toRem, hexToRgbA } from "../../utils"
import theme from "../../theme"

export default css`

	.row {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.plane-icon {
		width: 40px;
		height: auto;
	}

	.info {
		padding-bottom: 20px;
		min-height: 60px;
	}


	.airline>span {
		margin-left: 10px;
	}

	td {
		padding: 10px 2.5px;
	}

	.truck-logo {
		width: ${toRem(50)};
	}

	.leg2 {
		padding-top: 20px;
		padding-bottom: 0;
		border-top: 5px dotted rgba(37, 37, 50, 0.3);
	}

	.test {
		display: flex;
		flex-direction: column;
	}

	span {
		min-height: 22px;
	}

	.chevron {
		width: ${toRem(20)};
		margin: ${toRem(10)};
		transition-duration: 0.25s;
	}

	.rotated {
		transform: rotate(-90deg);
		transition-duration: 0.25s;
	}
`